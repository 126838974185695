export default {
  "item-status": "cOL",
  "item-date": "cOT",
  "item-amount": "cOz body-1",
  "item-amount-credit": "cOa",
  "row": "cOH",
  "icon": "cOO",
  "disabled": "cOm",
  "icon-container": "cOp",
  "text-container": "cOx",
  "text-row": "cOw",
  "item-details": "cON",
  "counterparty-name": "cOv",
  "column": "cOo",
  "item-amount-disabled": "cOn",
  "attachment-tooltip": "cOi",
  "bank-account-avatar": "cOA"
};
