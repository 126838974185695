export default {
  "container": "ctK",
  "container--collapsed": "ctG",
  "spin": "ctr",
  "cta-tooltip": "ctb",
  "cta--collapsed": "cty",
  "cta--expanded": "ctU",
  "collapsed-svg-icon": "ctj",
  "expanded-svg-icon": "ctW",
  "generate-logo-cta": "ctu",
  "badge": "cMS",
  "scaleDown": "cMc",
  "fadeIn": "cMq",
  "textRiseButton": "cMZ",
  "cta-description": "cMR",
  "textRiseDescription": "cMQ"
};
