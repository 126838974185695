export default {
  "account-description": "cpz",
  "account-identification": "cpa",
  "account-avatar": "cpH",
  "account-name": "cpO",
  "account-balance": "cpm body-2",
  "status": "cpp",
  "external-accounts-disclaimer": "cpx",
  "failed-connection": "cpw"
};
