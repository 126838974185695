export default {
  "company-logo": "cLq",
  "list-item": "cLZ",
  "link": "cLR",
  "title-wrapper": "cLQ",
  "title": "cLe body-2",
  "subtitle": "cLB caption",
  "truncated-text": "cLE",
  "image-placeholder": "cLd"
};
