export default {
  "summary-description": "cIE",
  "detail": "cId",
  "detail-value": "cII",
  "account-icon": "cIt",
  "color-secondary": "cIM",
  "beneficiary-info": "cIP",
  "currency": "cIl",
  "beneficiary-name": "cIX",
  "separator": "cIC",
  "instant-subtitle": "cIk",
  "instant-wrapper": "cIJ",
  "instant-header": "cIs",
  "instant-toggle": "cIF",
  "instant--disabled": "cIg",
  "limit-exceeded-disclaimer": "cID",
  "sticky-panel": "cIV",
  "total-interest": "cIL",
  "tooltip-icon": "cIT",
  "tooltip": "cIz",
  "payment-options": "cIa"
};
