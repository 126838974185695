export default {
  "details": "cDH",
  "details--ff": "cDO",
  "header-container": "cDm",
  "icon": "cDp",
  "line-container": "cDx",
  "info-line": "cDw",
  "body-details": "cDN",
  "body-details-header": "cDv",
  "body-details-1": "cDo",
  "body-details-2": "cDn",
  "body-details-3": "cDi",
  "body-details-4": "cDA"
};
