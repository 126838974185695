export default {
  "integration-item": "cls",
  "icon-container": "clF",
  "content": "clg",
  "title-container": "clD",
  "new-badge": "clV",
  "title": "clL",
  "description": "clT",
  "no-attributes": "clz",
  "connect-cta": "cla"
};
