export default {
  "invitations-password-form": "ckA",
  "mobile-mode": "ckY",
  "logo": "ckh",
  "header": "ckf",
  "controls": "ckK",
  "footer": "ckG",
  "submit-cta": "ckr",
  "subtitle": "ckb"
};
