export default {
  "wrapper": "ctd input-field mb-4",
  "error": "ctI",
  "inactive": "ctt",
  "hidden-value": "ctM",
  "percentage-sign": "ctP",
  "black": "ctl",
  "input-field": "ctX",
  "disabled": "ctC"
};
