export default {
  "wrapper": "cMe",
  "close-button": "cMB",
  "content-wrapper": "cME",
  "main": "cMd",
  "main-content": "cMI",
  "description": "cMt",
  "mt-26": "cMM",
  "mt-62": "cMP",
  "user-prompt": "cMl",
  "user-prompt-inappropriate-warning": "cMX",
  "logo-preview": "cMC",
  "static-loader": "cMk",
  "loading-state-illustration": "cMJ",
  "sticky-panel": "cMs"
};
