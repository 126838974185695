export default {
  "item": "cgW",
  "selected": "cgu",
  "hover-revised": "cDS",
  "asset-container": "cDc",
  "asset": "cDq",
  "revoked-icon": "cDZ",
  "pending-icon": "cDR",
  "revoked": "cDQ",
  "revised-revoke": "cDe",
  "pending": "cDB",
  "revised-pending": "cDE",
  "invitable": "cDd",
  "info": "cDI",
  "name": "cDt",
  "email": "cDM",
  "actions": "cDP",
  "invitable-member-delete": "cDl",
  "invitable-member-invite": "cDX"
};
