export default {
  "y-axis-placeholder": "czH",
  "y-axis-navigable": "czO",
  "y-axis-tick": "czm",
  "x-axis-placeholder": "czp",
  "x-axis-navigable": "czx",
  "x-axis-tick-wrapper": "czw",
  "x-axis-tick": "czN",
  "chart-loading-renderer": "czv"
};
