export default {
  "container": "cmK",
  "label": "cmG",
  "disabled": "cmr",
  "small": "cmb caption",
  "large": "cmy body-2",
  "caption": "cmU",
  "amount": "cmj",
  "right-aligned": "cmW",
  "expanded": "cmu",
  "amount--current": "cpS",
  "amount--total": "cpc",
  "amount--sep": "cpq"
};
