export default {
  "color-option": "cta",
  "color-dot": "ctH",
  "any": "ctO",
  "red": "ctm",
  "orange": "ctp",
  "yellow": "ctx",
  "green": "ctw",
  "blue": "ctN",
  "purple": "ctv",
  "pink": "cto",
  "black": "ctn",
  "grey": "cti",
  "brown": "ctA",
  "color-name": "ctY"
};
