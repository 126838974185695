export default {
  "navigation-dropdown": "cVo",
  "expanded": "cVn",
  "trigger": "cVi",
  "trigger-title-icon": "cVA",
  "collapsed": "cVY",
  "responsive": "cVh",
  "logo": "cVf",
  "active": "cVK",
  "trigger-title": "cVG",
  "content": "cVr",
  "content-container": "cVb",
  "dropdown-icon": "cVy",
  "link": "cVU"
};
