export default {
  "notifications-settings": "cLM",
  "opened": "cLP",
  "icon": "cLl",
  "header": "cLX",
  "header-toggle": "cLC",
  "avatar-image": "cLk",
  "title": "cLJ",
  "toggle": "cLs"
};
