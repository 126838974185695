export default {
  "fieldset": "cJQ",
  "subscription-period": "cJe",
  "automatic-number-section": "cJB",
  "automatic-number-label": "cJE",
  "icon-tooltip": "cJd",
  "date-picker": "cJI",
  "label": "cJt",
  "header-text-field": "cJM",
  "header-text-field-container": "cJP",
  "header-text-field-add-button": "cJl btn btn--tertiary",
  "header-text-field-close-button": "cJX btn btn--icon-only btn--tertiary btn--small",
  "read-only-frequency": "cJC",
  "read-only": "cJk"
};
