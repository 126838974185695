export default {
  "step-container": "cEI",
  "content": "cEt",
  "beneficiary": "cEM",
  "beneficiary-name": "cEP",
  "trusted": "cEl",
  "amount-disclaimer": "cEX",
  "purpose-subtitle": "cEC",
  "purposes": "cEk",
  "purpose-error": "cEJ",
  "sticky-panel": "cEs"
};
