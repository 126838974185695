export default {
  "row": "cTR",
  "terminated": "cTQ",
  "status-avatar": "cTe",
  "amount": "cTB body-1",
  "item-info": "cTE",
  "date-cell": "cTd",
  "cell": "cTI body-2",
  "status-icon": "cTt",
  "amount-cell": "cTM",
  "item-name-cell": "cTP",
  "item-name-container": "cTl"
};
