export default {
  "card": "cFQ",
  "focus": "cFe",
  "title-format": "cFB",
  "header": "cFE",
  "headings": "cFd",
  "title": "cFI title-4",
  "main": "cFt title-3",
  "footer": "cFM"
};
