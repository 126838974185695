export default {
  "wrapper": "cOY",
  "header": "cOh",
  "fadeIn": "cOf",
  "header-title": "cOK title-4",
  "transactions-container": "cOG",
  "placeholder-title": "cOr",
  "transaction-item-list-container": "cOb",
  "transaction-item-list": "cOy",
  "item": "cOU"
};
