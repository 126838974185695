export default {
  "container": "cVz",
  "close-button": "cVa",
  "content-wrapper": "cVH",
  "main": "cVO",
  "description": "cVm",
  "calculation-item": "cVp",
  "total-km": "cVx",
  "calculation-details": "cVw",
  "btn": "cVN"
};
