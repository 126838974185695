export default {
  "body": "cDC",
  "members": "cDk",
  "members-results": "cDJ",
  "members-list": "cDs",
  "members-title": "cDF caption-bold",
  "member": "cDg",
  "empty-illustration": "cDD",
  "empty-title": "cDV title-3",
  "empty-description": "cDL body-2",
  "invitable-members": "cDT",
  "details": "cDz",
  "pagination-footer": "cDa"
};
