export default {
  "bucket-item": "clR",
  "button": "clQ",
  "content": "cle",
  "bucket-icon": "clB",
  "bucket-title-container": "clE",
  "bucket-new-badge": "cld",
  "bucket-title": "clI",
  "arrow-icon": "clt"
};
