export default {
  "member-details": "cgI",
  "header": "cgt",
  "no-email": "cgM",
  "header-img": "cgP",
  "invited-persona": "cgl",
  "revoked-persona": "cgX",
  "invited-icon": "cgC",
  "revoked-icon": "cgk",
  "revised": "cgJ",
  "invitable-icon": "cgs",
  "dropdown": "cgF",
  "header-body": "cgg",
  "status": "cgD",
  "name": "cgV title-3",
  "email": "cgL caption-bold",
  "actions": "cgT body-2",
  "actions-cards": "cgz",
  "actions-transactions": "cga",
  "body": "cgH",
  "expense-permissions-section": "cgO",
  "body-title": "cgm title-4",
  "body-attr": "cgp",
  "body-label": "cgx",
  "attr-buttons": "cgw",
  "body-role": "cgN small",
  "details-actions": "cgv",
  "actions-item": "cgo",
  "disclaimer": "cgn",
  "permissions": "cgi",
  "permission": "cgA",
  "permission-label": "cgY",
  "permission-check": "cgh",
  "permission-missing": "cgf",
  "editable-section": "cgK",
  "hris-placeholder": "cgG",
  "imported-from": "cgr",
  "integration-logo": "cgb",
  "permission-value": "cgy",
  "tooltip": "cgU",
  "check-icon": "cgj"
};
