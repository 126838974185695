export default {
  "card": "cTA",
  "header": "cTY",
  "avatar": "cTh",
  "title": "cTf",
  "subtitle": "cTK",
  "label": "cTG",
  "item": "cTr body-2",
  "header-icon": "cTb",
  "dropdown-menu": "cTy",
  "menu-item": "cTU body-2",
  "delete": "cTj"
};
