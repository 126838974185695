export default {
  "members-loading": "cVS",
  "members-loading--ff": "cVc",
  "header": "cVq",
  "header-search-bar": "cVZ",
  "body": "cVR",
  "members": "cVQ",
  "placeholder-container": "cVe",
  "icon": "cVB",
  "details": "cVE",
  "member-loading": "cVd",
  "member-loading--no-ff": "cVI",
  "body-members": "cVt",
  "body-details": "cVM"
};
