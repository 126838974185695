export default {
  "banner": "czI",
  "content": "czt",
  "title-row": "czM",
  "title": "czP title-3",
  "badge": "czl",
  "text": "czX body-2",
  "missing-info-disclaimer": "czC",
  "button": "czk btn btn--primary",
  "spinner": "czJ",
  "image": "czs"
};
