/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { BadgeStatus } from '@repo/design-system-kit';

import { INSURANCE_PRODUCT_TYPES } from 'qonto/constants/insurance-hub';
import {
  getInsuranceContractPaymentFrequency,
  getInsuranceContractStatusProps,
} from 'qonto/utils/insurance-hub/insurance-contract';

export default class InsuranceHubTableItemComponent extends Component {
  badgeStatus = BadgeStatus;

  @service intl;
  @service localeManager;

  get paymentFrequency() {
    let { paymentFrequency } = this.args.item;

    return getInsuranceContractPaymentFrequency({
      intl: this.intl,
      frequency: paymentFrequency,
    });
  }

  get insuranceContractStatusProps() {
    let { status } = this.args.item;

    return getInsuranceContractStatusProps({ intl: this.intl, status });
  }

  get renewalDate() {
    let { isExpired, renewalDate } = this.args.item;

    return isExpired || !renewalDate
      ? '-'
      : dateToken({
          date: renewalDate,
          token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
          locale: this.localeManager.locale,
        });
  }

  get policyProductType() {
    let { type, name } = this.args.item;

    let productTypes = {
      [INSURANCE_PRODUCT_TYPES.BUSINESS_LIABILITY]: this.intl.t(
        'insurance-hub.policies.product-type.business_liability'
      ),
      [INSURANCE_PRODUCT_TYPES.COLLECTIVE_HEALTCARE]: this.intl.t(
        'insurance-hub.policies.product-type.collective_healthcare'
      ),
      [INSURANCE_PRODUCT_TYPES.CYBER]: this.intl.t('insurance-hub.policies.product-type.cyber'),
      [INSURANCE_PRODUCT_TYPES.DECENNIAL]: this.intl.t(
        'insurance-hub.policies.product-type.decennial'
      ),
      [INSURANCE_PRODUCT_TYPES.HEALTHCARE]: this.intl.t(
        'insurance-hub.policies.product-type.healthcare'
      ),
      [INSURANCE_PRODUCT_TYPES.IT_HARDWARE_DAMAGE]: this.intl.t(
        'insurance-hub.policies.product-type.it_hardware_damage'
      ),
      [INSURANCE_PRODUCT_TYPES.KEY_PERSON]: this.intl.t(
        'insurance-hub.policies.product-type.key_person'
      ),
      [INSURANCE_PRODUCT_TYPES.LEGAL_PROTECTION]: this.intl.t(
        'insurance-hub.policies.product-type.legal_protection'
      ),
      [INSURANCE_PRODUCT_TYPES.LEGAL_SERVICE]: this.intl.t(
        'insurance-hub.policies.product-type.legal_service'
      ),
      [INSURANCE_PRODUCT_TYPES.MOTOR_FLEET]: this.intl.t(
        'insurance-hub.policies.product-type.motor_fleet'
      ),
      [INSURANCE_PRODUCT_TYPES.MULTI_RISK]: this.intl.t(
        'insurance-hub.policies.product-type.multi_risk'
      ),
      [INSURANCE_PRODUCT_TYPES.OFFICE]: this.intl.t('insurance-hub.policies.product-type.office'),
      [INSURANCE_PRODUCT_TYPES.PUBLIC_LIABILITY]: this.intl.t(
        'insurance-hub.policies.product-type.public_liability'
      ),
      [INSURANCE_PRODUCT_TYPES.REVENUE_LOSS]: this.intl.t(
        'insurance-hub.policies.product-type.revenue_loss'
      ),
    };

    return productTypes[type] || name;
  }
}
