export default {
  "container": "cHp",
  "indicator-container": "cHx",
  "transactions-container": "cHw",
  "indicator": "cHN",
  "cashflow-container": "cHv",
  "cashflow": "cHo",
  "donut-container": "cHn",
  "donut": "cHi"
};
