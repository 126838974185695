export default {
  "container": "cEu",
  "content": "cdS",
  "quote-information-item": "cdc",
  "divider": "cdq",
  "tooltip": "cdZ",
  "amount": "cdR title-4",
  "align-left": "cdQ",
  "sticky-panel": "cde"
};
