export default {
  "step-container": "cEa",
  "budget-tooltip": "cEH",
  "content": "cEO",
  "summary-description": "cEm",
  "detail": "cEp",
  "separator": "cEx",
  "detail-value": "cEw",
  "color-secondary": "cEN",
  "beneficiary-info": "cEv",
  "currency": "cEo",
  "fees-tooltip": "cEn"
};
