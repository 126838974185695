export default {
  "bullet": "cHB",
  "slice-0": "cHE",
  "slice-1": "cHd",
  "slice-2": "cHI",
  "slice-3": "cHt",
  "slice-4": "cHM",
  "slice-5": "cHP",
  "slice-6": "cHl",
  "slice-other": "cHX",
  "slice-uncategorized": "cHC",
  "label": "cHk",
  "ellipsis": "cHJ",
  "rest": "cHs",
  "previous-date-range": "cHF caption"
};
