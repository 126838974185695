export default {
  "header": "cJG",
  "center": "cJr",
  "header-placeholder": "cJb",
  "placeholder": "cJy",
  "name-block": "cJU",
  "block": "cJj",
  "detail-block": "cJW",
  "border-top": "cJu"
};
