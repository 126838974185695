export default {
  "mt-16": "cVC",
  "title": "cVk",
  "edit-organization-form": "cVJ",
  "beside-block": "cVs",
  "info-circle": "cVF",
  "hollow": "cVg",
  "filled": "cVD",
  "radio": "cVV",
  "cancel-button": "cVL",
  "error-message": "cVT"
};
