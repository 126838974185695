export default {
  "header-cell": "csJ",
  "empty": "css",
  "header-content": "csF caption-bold",
  "active": "csg",
  "align-right": "csD",
  "first-col": "csV",
  "mid-col": "csL",
  "status-col": "csT"
};
