export default {
  "sidebar-section": "cCS body-2",
  "sidebar-section-header": "cCc body-1 mb-4",
  "providers-logos": "cCq",
  "risks": "cCZ",
  "tag": "cCR tag",
  "external-link-icon": "cCQ",
  "disclaimer": "cCe",
  "divider": "cCB"
};
