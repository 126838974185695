export default {
  "content": "cBa",
  "error-message": "cBH",
  "wrapper": "cBO",
  "banner": "cBm",
  "banner-content": "cBp",
  "banner-text": "cBx",
  "banner-image": "cBw",
  "banner-title": "cBN",
  "banner-description": "cBv body-2",
  "link": "cBo",
  "plans-comparison": "cBn",
  "plan-box": "cBi",
  "arrow": "cBA",
  "arrow-icon": "cBY",
  "link-downgrade": "cBh",
  "stroke-text": "cBf"
};
