export default {
  "sidebar": "csS",
  "top-section": "csc",
  "title": "csq",
  "primary-action": "csZ",
  "edit-icon": "csR",
  "close-icon": "csQ",
  "box-wrapper": "cse",
  "bottom-action": "csB",
  "related-invoices": "csE",
  "related-invoices-title": "csd mb-16 caption-bold",
  "box": "csI",
  "box-element": "cst",
  "cancel-text": "csM",
  "box-header": "csP",
  "row": "csl",
  "greyed-out": "csX",
  "struck-through": "csC",
  "share-mandate-link": "csk"
};
