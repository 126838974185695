export default {
  "wrapper": "cXK",
  "content": "cXG",
  "back": "cXr btn btn--tertiary",
  "heading": "cXb",
  "heading-content": "cXy",
  "logo": "cXU",
  "placeholder": "cXj",
  "description": "cXW body-2",
  "cta": "cXu"
};
