export default {
  "banner": "cTa",
  "inner": "cTH",
  "header": "cTO title-3",
  "content": "cTm",
  "subtitle": "cTp body-1",
  "list": "cTx",
  "item": "cTw body-1",
  "icon": "cTN",
  "cta": "cTv btn btn--primary",
  "spinner": "cTo",
  "illustration": "cTn",
  "image": "cTi"
};
