export default {
  "wrapper": "cOe",
  "header": "cOB",
  "fadeIn": "cOE",
  "header-content": "cOd",
  "with-cta": "cOI",
  "header-title": "cOt title-4",
  "advanced-filters-trigger": "cOM",
  "filters-button": "cOP",
  "filters-button-upsell": "cOl",
  "filters-upsell-item": "cOX",
  "filters-badge": "cOC",
  "chart-container": "cOk",
  "chart-legend-container": "cOJ mb-24",
  "chart-watermark-container": "cOs",
  "chart-watermark": "cOF",
  "placeholder-title": "cOg",
  "placeholder-subtitle": "cOD",
  "advanced-filters": "cOV"
};
