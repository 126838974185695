export default {
  "donut-chart-container": "czf",
  "donut-chart-wrapper": "czK",
  "chart": "czG",
  "donut-chart-legend-container": "czr caption",
  "donut-chart-legend-item-empty": "czb",
  "donut-chart-legend-bullet-empty": "czy",
  "donut-chart-legend-bullet": "czU",
  "donut-chart-legend-text-empty": "czj",
  "donut-chart-legend-text": "czW",
  "donut-chart-custom-label-uncategorized": "czu",
  "custom-label-uncategorized-button": "caS body-1",
  "donut-chart-legend": "cac",
  "donut-chart-legend-item": "caq",
  "donut-chart-legend-other-item": "caZ",
  "donut-chart-legend-uncategorized-item": "caR",
  "donut-chart-legend-text-name": "caQ",
  "tooltip": "cae"
};
