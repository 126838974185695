export default {
  "container": "ckE",
  "form": "ckd",
  "form-header": "ckI",
  "form-subtitle": "ckt",
  "form-input": "ckM",
  "left-input": "ckP",
  "right-input": "ckl",
  "label-fix": "ckX",
  "x-validated-input__header": "ckC",
  "form-dropzone": "ckk",
  "form-footer": "ckJ",
  "submit-cta": "cks",
  "checklist": "ckF",
  "about-you-form": "ckg",
  "name-inputs": "ckD",
  "birth-inputs": "ckV"
};
