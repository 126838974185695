export default {
  "mandate-list-item": "cFn",
  "selected": "cFi",
  "status-avatar": "cFA",
  "infos": "cFY",
  "info-title": "cFh body-1",
  "info-title-suspended": "cFf",
  "info-title-status-suspended": "cFK",
  "info-mandate-count": "cFG body-2"
};
