export default {
  "container": "cXm",
  "logo": "cXp",
  "title": "cXx title-4",
  "description": "cXw body-2",
  "metadata": "cXN",
  "risks-container": "cXv",
  "label": "cXo caption",
  "risks": "cXn",
  "tag": "cXi tag"
};
