export default {
  "custom-period-wrapper": "caw",
  "fadeIn": "caN",
  "custom-period-description": "cav body-2",
  "header": "cao",
  "arrow-left": "can",
  "body": "cai",
  "period-wrapper": "caA",
  "back-button": "caY",
  "footer": "cah"
};
