export default {
  "card": "cMp",
  "text-container": "cMx",
  "text-title": "cMw title-3",
  "text-subtitle": "cMN body-1",
  "text-cta": "cMv body-1",
  "text-cta-icon": "cMo",
  "image": "cMn",
  "image-illustration": "cMi"
};
