export default {
  "details-box": "cLn",
  "picto": "cLi",
  "picto-status": "cLA",
  "ghost": "cLY",
  "primary-info": "cLh",
  "date": "cLf",
  "account-infobox": "cLK",
  "declined-disclaimer": "cLG",
  "nrc-download": "cLr"
};
