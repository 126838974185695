export default {
  "container": "cdI",
  "content": "cdt",
  "sticky": "cdM",
  "divider": "cdP",
  "tooltip": "cdl",
  "detail": "cdX",
  "detail-value": "cdC",
  "color-secondary": "cdk",
  "beneficiary-information": "cdJ",
  "beneficiary-source": "cds",
  "currency": "cdF",
  "break-word": "cdg",
  "disclaimers": "cdD",
  "sticky-panel": "cdV"
};
