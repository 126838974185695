export default {
  "account-container": "cmX",
  "badge": "cmC",
  "subtitle": "cmk title-4",
  "accounts-filter": "cmJ",
  "account-filter-label": "cms",
  "status": "cmF",
  "account-info": "cmg",
  "balance": "cmD",
  "revealed-balance": "cmV",
  "hidden-balance": "cmL"
};
