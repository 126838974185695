export default {
  "card": "czc",
  "header": "czq",
  "avatar": "czZ",
  "title": "czR",
  "subtitle": "czQ",
  "label": "cze",
  "item": "czB body-2",
  "header-icon": "czE",
  "mt-8": "czd"
};
