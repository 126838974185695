export default {
  "container": "cCk",
  "header": "cCJ",
  "content": "cCs",
  "info-list": "cCF",
  "info-label": "cCg body-2",
  "info-value": "cCD body-2",
  "provider": "cCV",
  "policy-container": "cCL",
  "policy-label": "cCT body-2",
  "policy-number": "cCz body-2",
  "copy-to-clipboard": "cCa",
  "button": "cCH btn btn--primary btn--stretch btn--large"
};
