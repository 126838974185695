export default {
  "container": "cJD",
  "form-container": "cJV",
  "title": "cJL",
  "title-border": "cJT",
  "tabs-container": "cJz",
  "preview-container": "cJa",
  "email-preview-container": "cJH",
  "close-button": "cJO btn btn--icon-only btn--tertiary btn--large"
};
