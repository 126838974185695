export default {
  "guests-loading": "cPE",
  "header": "cPd",
  "body": "cPI",
  "members": "cPt",
  "title": "cPM caption-bold",
  "placeholder-container": "cPP",
  "icon": "cPl",
  "details": "cPX"
};
